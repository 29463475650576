@import 'src/variable';
.C_auth {
  padding: 0 40px;
  button {
    &.clicked {
      color: $main-blue-color !important;
    }
  }

  .header {
    display: flex;
    flex-direction: column;
    padding: 60px 0;
    align-items: center;
    h1 {
      color: black;
      font-size: 24px;
      font-weight: 400;
      margin-bottom: 16px;
    }
    p {
      text-align: center;
      color: $main-grey-color;
      font-size: 14px;
      font-weight: 400;
      span {
        color: #ed8c8c;
      }
    }
  }

  form {
    display: flex;
    flex-direction: column;

    .input_container {
      position: relative;
      display: flex;
      flex-direction: row;
      margin-bottom: 24px;

      &.name_and_check {
        padding: 9px 0;
        border-bottom: 1px solid $grey-color;
        background: white;
        .input_box {
          &.name {
            flex-grow: 1;
            input {
              width: 90% !important;
            }
          }
          &.check_foreigner {
            button {
              display: flex;
              flex-direction: row;
              align-items: center;

              color: $main-grey-color;
              font-size: 18px;
              font-weight: 400;
              svg {
                width: 16px;
                height: 16px;
                margin-right: 8px;
              }
            }
          }
        }
      }

      &.birthday {
        .input_box {
          padding: 9px 0;
          border-bottom: 1px solid $grey-color;
        }
        .aaa {
          display: flex;
          flex-direction: row;
          align-items: center;
          p {
            margin-right: 12px;
          }
          svg {
            margin-right: 8px;
          }
        }
      }

      &.gender {
        display: flex;
        flex-direction: row;
        align-items: center;
        P {
          margin-right: 40px;
        }
        button {
          margin-right: 24px;
          svg {
            margin-right: 8px;
          }
        }
      }

      &.phone_number {
        .input_box {
          select {
            color: #9698a3;
            font-size: 18px;
            font-weight: 400;
            border-bottom: 1px solid $grey-color;
            padding: 9px 0;
            min-width: 140px;
          }
          input {
            margin-left: 12px;
            border-bottom: 1px solid $grey-color;
            padding: 9px 0 9px 9px;
          }
        }
      }

      &.password {
        padding: 9px 0;
        border-bottom: 1px solid $grey-color;
        .input_box {
          width: 100%;
        }
      }

      &.auth_number{
        margin-top: 22px;
        .input_box {
          border-bottom: 1px solid $grey-color;
          flex-grow: 1;
        }
        button{
          margin-left: 12px;
          border-radius: 4px;
          background: $main-blue-color;
          display: flex;
          height: 34px;
          padding: 8px;
          justify-content: center;
          align-items: flex-start;
          flex-shrink: 0;
          color: #FFF;
          text-align: center;
          font-size: 14px;
          font-style: normal;
          font-weight: 400;
          line-height: 18px; /* 128.571% */
        }
      }

      .error_message {
        color: red;
        font-size: 12px;
        position: absolute;
        bottom: -20px;
      }
    }
    .agreement_container {
      margin-top: 32px;
      & > div {
        margin: 10px 0;
        color: $main-grey-color;
        font-size: 14px;
        font-weight: 400;

        display: flex;
        flex-direction: row;
        align-items: center;
        svg {
          cursor: pointer;
          margin-right: 8px;
        }
        u {
          cursor: pointer;
          margin-right: 4px;
        }
        &.total {
          cursor: pointer;
          color: $main-blue-color;
          font-size: 14px;
          font-weight: 700;
        }
      }
    }
    .dummy_container {
      height: 15vh;
    }
    .bottom_container {
      position: absolute;
      left: 50%;
      bottom: 30px;
      transform: translate(-50%, -30px);
      width: 100%;

      padding: 0 40px;
      display: flex;
      flex-direction: column;
      align-items: center;
      button,
      div {
        background: $main-grey-color;
        display: flex;
        height: 48px;
        padding: 12px;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        gap: 10px;
        align-self: stretch;

        color: white;
        text-align: center;
        font-size: 18px;
        font-weight: 700;

        margin-bottom: 16px;
      }
      button {
        background: $main-blue-color;
      }

      a {
        color: $main-blue-color;
        text-align: center;
        font-size: 14px;
        font-weight: 400;
      }
    }
  }
  .login_modal_container {
    .modal_main {
      padding: 0 !important;
      .agreement_modal_header{
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        height: 60px;
        background-color: white;
        z-index: 1;
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 30px;
      }
      .agreement_modal_content {
        width: 100%;
        padding: 16px;
        height: calc(100vh - 65px);
        overflow: unset;
        iframe {
          height: 100%;
          width: 100%;
        }
      }
      .agreement_modal_btn_box {
        position: absolute;
        bottom: -50px;
        width: 100%;
        display: flex;
        flex-direction: row;
        height: 50px;
        button {
          width: 50%;
          background: $line-grey-color;
          color: $dark-grey-color;
          &.true {
            background: $main-blue-color;
            color: white;
          }
        }
      }
    }
  }
}
@media all and (max-width: 1024px) {
  .C_LoginEntrance{
    flex-grow: 1;
    overflow-y: scroll;
  }
  .C_auth {
    padding: 0 40px;
    height: 100%;
    max-width: 480px;
    width: 100%;
    margin: auto;
    form{
      .bottom_container{
        position: unset;
        transform: unset;
        width: 100%;
        bottom: 20px;
        padding: 28px 0 60px 0;
      }
    }
  }
}
@media all and (max-width: 480px) {
  .C_auth {
    max-width: 100%;
  }
  .login_modal_container {
    .modal_container{
      height: calc(100vh - 50px) !important;
      .modal_header{
        height: 60px;
        padding: unset!important;
      }
      .modal_main{
        overflow: hidden;
        max-height: 100%!important;
      }
    }
  }

}
@media all and (max-width: 320px) {
  .C_auth {
    padding: 24px;
  }
}