:root {
  --toastify-color-dark: rgba(0, 0, 0, 0.72) !important;
  --toastify-toast-min-height: 42px!important;
  --toastify-toast-max-height: 42px!important;
  --toastify-z-index:3!important;
}
.Toastify__toast-body {
  padding: 0!important;
  text-align: center!important;
}

@keyframes Toastify__bounceIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
.swirl-in-fwd {
  animation: Toastify__bounceIn 0.3s ease-out both;
}
@keyframes Toastify__bounceOut {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}
.Toastify__bounceOut {
  animation: Toastify__bounceOut 0.3s ease-in both;
}

@media only screen and (max-width: 480px) {
  .Toastify__toast {
    margin-bottom: 0;
    border-radius: 4px!important;
  }
}