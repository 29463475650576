$black-color: #333333;
$main-grey-color: #9698a3;
$main-blue-color: #1438cc;
$line-grey-color: #f2f3f6;
$light-grey-color: #e6e8ee;
$grey-color: #bfc1ca;
$text-grey-color: #dadbdf;
$dark-grey-color: #3c3e44;
$bg-grey-color: #fafafa;
$bg-grey-color-two: #f6f6f8;
$top_ad_height: 60px;
