.C_test {
  margin: auto;
  max-width: 1200px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  justify-items: center;
  .test_item_box {
    width: 100%;
    border: 1px solid gray;
    padding: 40px;
    button{
      background-color: black;
      color: white;
      padding: 10px;
      border-radius: 5px;
    }
  }
  .C_WebViewScrollTest{
    position: relative;
    width: 100%;
    overflow-y: auto;
    .content{
      width: 100%;
      height: calc(100vh - 100px);
      overflow: scroll;
    }
    .input_box{
      position: sticky;
      height: 100px;
      input{
        width: 100%;
        height: 100px;
        position: absolute;
        bottom: 0;
        left: 0;
        right: 0;
        outline: #1f4bd5;
        border: 1px solid #1f4bd5;
      }
      margin-bottom: 100px;
    }

    .make-scrollable{
      position: absolute;
      top: 0;  // top 또는 left (혹은 둘다) 속성을 사용하여
      left: 0; // wrapper를 덮음
      width: 1px;
      height: calc(100vh + 1px); // height를 100%보다 1px높게 잡아 실제로 scroll이 되도록 만듭니다.
    }
  }
}
body{
  overflow: hidden!important;
}