@import 'src/variable.scss';
$modal_inner-padding: 2.5rem;
.privacy_checkbox_label,
.needLeafletLabel {
  font-size: 0.8rem !important;
}
.modal {
  display: none;
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 10000;
  background-color: rgba(0, 0, 0, 0.6) !important;
  &.left {
    justify-content: end;
    .modal_container {
      margin: unset;
    }
  }

  .close_btn {
    outline: none;
    cursor: pointer;
    border: 0;
  }

  .modal_container {
    height: calc(100vh - 50px);
    width: 500px;
    margin: 0 auto;
    background-color: #fff;
    max-width: 900px;
    position: relative;
    /* 팝업이 열릴때 스르륵 열리는 효과 */
    animation: modal-show 0.3s;

    .modal_header {
      top: 0;
      right: 0;
      width: 50px;
      position: absolute;
      display: flex;
      padding: 16px;
      font-weight: 700;
      font-size: 1.2rem;
      background-color: white;
        z-index: 10;
      p {
        flex-grow: 1;
        color: $black-color;
      }

      img,
      svg {
        cursor: pointer;
        width: 20px;
        height: 20px;

        // div 안의 img를 가운데 정렬하기 위해선 img에 margin: auto;값을 주면 됨
        margin: auto;
      }
    }

    .modal_main {
      color: $black-color;
      padding: $modal_inner-padding;
      border-bottom: 1px solid #dee2e6;
      border-top: 1px solid #dee2e6;
      overflow: auto;
      max-height: 80vh;
    }

    .modal_footer {
      padding: 12px 16px;
      text-align: right;

      .close_btn {
        padding: 6px 12px;
        color: #fff;
        background-color: #6c757d;
        border-radius: 5px;
        font-size: 13px;
      }
    }
  }

  &.openModal {
    display: flex;
    align-items: start;
    /* 팝업이 열릴때 스르륵 열리는 효과 */
    animation: modal-bg-show 0.3s;
  }
}
// ******************************************************
.contact_popup {
  background-color: #fff;
  color: #2e3448;
  overflow: auto;
  font-size: 12.5px;
  font-weight: 500;
  -webkit-overflow-scrolling: touch;
}

.popup_content {
  width: 488px;
  text-align: left;

  select {
    // 사파리에서는 select에 padding이 먹지 않음 // 아래 방식으로 적용해야함
    height: 44px;
    text-indent: 5px;
    line-height: 44px;
  }

  .error_message {
    font-weight: 500;
    color: red;
    text-align: left;
    font-size: 0.6rem;
    margin-bottom: 20px;
  }

  .input_box {
    line-height: 21px;
    margin-top: 20px;

    font-size: 0.9rem;
    font-weight: 600;

    .privacyPopup {
      text-decoration: underline;
      font-weight: 600;
      color: #2e3448;
      cursor: pointer;
    }
    .essential {
      color: #ff2966;
      padding-left: 5px;
    }
    input,
    textarea,
    select {
      border-radius: 4px;
      background-color: #f8f9fa;
      border: 1px solid #e3e4e7;
      font-size: 12px;
      color: #2e3448;
      letter-spacing: 0;
      margin-top: 10px;
      width: 100%;

      &:focus {
        outline: none;
      }
      &.error {
        border: 1px solid #ff2966;
        box-shadow: 0 0 6px #ff2d6980;
      }
    }
    input {
      height: 44px;
      padding: 0 3% 0 3%;
    }
    textarea {
      padding: 1% 3% 0 3%;
    }

    &.checkbox {
      display: flex;
      align-items: center;
      input {
        width: 18px;
        height: 18px;
        margin-right: 5px;
        margin-bottom: 9px;
      }
    }

    label {
      font-size: 1.1rem;
      font-weight: 600;
      .label_sub_box {
        margin-left: 5px;
        font-size: 0.7rem;
        color: #8b90a0;
      }
    }
  }
  .btn_box {
    width: 100%;
    height: auto;
    padding-top: 46px;
    text-align: center;
    padding-bottom: 14px;

    button {
      width: 260px;
      height: 50px;
      border: 0;
      color: #fff;
      font-size: 15px;
      font-weight: 500;
      background-color: #1f4bd5;
      outline: none;
      cursor: pointer;
    }
  }
}

.popup_exit_btn {
  position: absolute;
  width: 26px;
  height: 26px;
  top: 50px;
  right: 50px;
  cursor: pointer;
  z-index: 99;
  img {
    width: 100%;
    height: auto;
  }
}

// TODO : 아래 부분 실제로 사용하는지 보고 사용안하면 삭제하기 ************************************
.contact_popup.service_popup {
  width: 900px;
  overflow: hidden;
}

.contact_popup.service_popup .popup_box_in {
  width: 100%;
  margin: 0;
}

.contact_popup.service_popup .popup_box_in .title {
  width: auto;
  font-size: 28px;
  font-weight: 600;
  border-bottom: 1px solid #efeff1;
  white-space: normal;
  padding: 30px 0 30px 40px;
  margin-bottom: 0px;
  position: relative;
}

.contact_popup.service_popup .popup_exit_btn {
  position: absolute;
  width: 26px;
  height: 26px;
  top: 40px;
  right: 40px;
  cursor: pointer;
}

.contact_popup.service_popup .service_text {
  width: auto;
  height: 100vh;
  background-color: #f6f7f9;
  overflow: auto;
  position: relative;
}

.contact_popup.service_popup .service_text .in {
  width: auto;
  height: calc(100vh - 250px);
  padding: 30px;
  position: absolute;
  overflow: auto;
}

/* ie용 스크롤 바*/
.contact_popup {
  scrollbar-arrow-color: #eceef1;
  scrollbar-track-color: #eceef1;
  scrollbar-base-color: #dadae0;
  scrollbar-face-color: #dadae0;
  scrollbar-3dlight-color: #dadae0;
  scrollbar-darkshadow-color: #dadae0;
  scrollbar-highlight-color: #dadae0;
  scrollbar-shadow-color: #dadae0;
}
/* Chrome, Safari용 스크롤 바 */

.contact_popup::-webkit-scrollbar {
  width: 8px;
}

.contact_popup::-webkit-scrollbar-button:start:decrement,
.contact_popup::-webkit-scrollbar-button:end:increment {
  display: block;
  width: 8px;
  height: 8px;
  background-color: rgba(0, 0, 0, 0.05);
}

.contact_popup::-webkit-scrollbar-track {
  background: rgba(0, 0, 0, 0.05);
}

.contact_popup::-webkit-scrollbar-thumb {
  background: rgba(0, 0, 0, 0.1);
  border-radius: 5px;
}

/* ie용 스크롤 바*/
.contact_popup.service_popup .service_text .in {
  scrollbar-arrow-color: #f6f7f9;
  scrollbar-track-color: #f6f7f9;
  scrollbar-base-color: #dadae0;
  scrollbar-face-color: #dadae0;
  scrollbar-3dlight-color: #dadae0;
  scrollbar-darkshadow-color: #dadae0;
  scrollbar-highlight-color: #dadae0;
  scrollbar-shadow-color: #dadae0;
}
/* Chrome, Safari용 스크롤 바 */

.contact_popup.service_popup .service_text .in::-webkit-scrollbar {
  width: 8px;
}

.contact_popup.service_popup .service_text .in::-webkit-scrollbar-button:start:decrement,
.contact_popup.service_popup .service_text .in::-webkit-scrollbar-button:end:increment {
  display: block;
  width: 8px;
  height: 8px;
  background-color: #f6f7f9;
}

.contact_popup.service_popup .service_text .in::-webkit-scrollbar-track {
  background: rgba(0, 0, 0, 0.05);
}

.contact_popup.service_popup .service_text .in::-webkit-scrollbar-thumb {
  background: rgba(0, 0, 0, 0.1);
  border-radius: 0px;
}
// ******************************************************
@keyframes modal-show {
  from {
    opacity: 0;
    margin-top: -50px;
  }
  to {
    opacity: 1;
    margin-top: 0;
  }
}
@keyframes modal-bg-show {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
// START *************************** 0px ~ 500px ***********************************
$modal_mobile_inner-padding: 1.2rem;
@media all and (max-width: 480px) {
  .modal {
    .modal_container {
      height: 100%;
      width: 100%;
      border-radius: 0;
      .modal_header {
        padding: $modal_mobile_inner-padding;
      }
      .modal_main {
        padding: $modal_mobile_inner-padding;
        border-bottom: none;
        max-height: 90%; // height vh가 아이폰에서는 적용 안함...

        .popup_content {
          width: 100%;
          .guide {
            line-height: 1.7;
          }

          .input_box {
            font-size: 1rem;
            &.checkbox {
              font-size: 0.8rem;
            }
            label {
              font-size: 1rem;
            }
          }
          .btn_box {
            padding-top: 16px;
            margin: 0;
          }
        }
      }
    }
  }
} // END *************************** 0px ~ 500px ***********************************

@media all and (max-width: 300px) {
  .popup_content {
    .btn_box {
      button {
        width: 240px;
        height: 45px;
      }
    }
  }
} // END *************************** 0px ~ 500px ***********************************
