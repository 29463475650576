@charset "UTF-8";
@tailwind base;
@tailwind components;
@tailwind utilities;
@import 'variable.scss';

/* region ****************************** 공통 설정 ***************************** */
#root {
  overflow: hidden !important;
}
* {
  margin: 0;
  padding: 0;
  font-family: 'pretendard', sans-serif;
}

ul,
ol,
li {
  list-style: none;
  padding-left: 0 !important;
  margin-bottom: 0 !important;
}

a {
  text-decoration: none !important;
}

// 최대 ContentBox의 넓이는 1200px로 제한
.max_content_box {
  max-width: 1200px;
  margin: auto;
}
h1 {
  font-weight: 700;
  font-size: 30px;
  text-align: center;
}
html{
  overflow: unset!important;
}
body {
  overscroll-behavior-y: none; // body 아래로 스크롤할경우 최상단일때 새로고침 하는것 막기
  overflow-y: unset!important;
  overflow-x: hidden !important;
  color: $black-color;
}
.only_mobile {
  display: none;
}
button {
  &:hover {
    opacity: 0.8 !important;
  }
}
div,
span {
  border-collapse: collapse;
}
input {
  /*ios대응*/
  appearance: none;
  -webkit-appearance: none;
  -webkit-border-radius: 0;
}
textarea{
  outline: none;
}
select {
  /*ios대응*/
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  background:url('./image/icon/arrow_bottom_two.svg') no-repeat right 9px center;
}
/* endregion ****************************** 공통 설정 ***************************** */
form {
  .input_box {
    input {
      width: 100%;
      outline: none;
      caret-color: $main-blue-color;
      &:focus {
      }
    }
  }
}
.C_InnerError {
  display: flex;
  flex-direction: column;
  align-items: center;
  height: calc(100vh - 50px);
  justify-content: center;
  p {
    color: #9698a3;
    text-align: center;
    font-size: 14px;
    font-weight: 400;
  }
}
.Toastify {
  .Toastify__toast-container--bottom-center {
    bottom: 100px;
    left: 50%;
    transform: translateX(-50%);
  }
}
.image_modal {
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  background-color: black;
  z-index: 5;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: start;
  .tob {
    width: 100%;
    height: 60px;
    flex-grow: 1;
    svg {
      cursor: pointer;
      width: 60px;
      height: 60px;
      padding: 8px;
      position: absolute;
      z-index: 3;
    }
    .center{
      display: flex;
      flex-direction: column;
      align-items: center;
      color: #FFF ;
      padding: 12px 0;
      .title{
        font-size: 16px;
        font-weight: 700;
      }
      .sub_title{
        opacity: 0.4;
        font-size: 12px;
        font-weight: 400;
      }
    }
  }
  .middle {
    margin: auto;
    img {
      max-width: 480px;
      max-height: 480px;
      width: 100vw;
      height: auto;

      object-fit: contain;
    }
    .react-transform-wrapper{
      height: 100% !important;
      display: flex;
      align-items: center;
      justify-content: center;
      .react-transform-component{
      }

    }
  }
  .guide {
    flex-grow: 1;
  }
  .bottom {
    svg {
      cursor: pointer;
    }
    height: 60px;
    display: flex;
    flex-direction: row;
    align-items: center;

    width: 100%;
    justify-content: space-between;
    padding: 0 10px;
  }
}
.toast_container{
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  word-break: break-all;
  -webkit-line-clamp: 1;
  .Toastify__toast-body{
    width: 100%;
    &>div{
      width: 100%;

    }
  }
}

.C_Modal{
  position: absolute;
  z-index: 1; // 사진, 세팅보다 아래
  &.show{
    transition: all 1s;
    opacity: 1;
  }
  &.hidden{
    opacity: 0;
    pointer-events: none;
    transition: all 0s;
  }

  // 메시지용은 디자인이 다름
  &.C_innerBlackLineModal{
    width: 95%;
    max-width: 460px;
    cursor: pointer;
    display: flex;

    bottom: 70px;
    left: 50%;
    transform: translate(-50%, -70px);
    border-radius: 18px;
    background: rgba(0, 0, 0, 0.72);

    padding: 6px;
    gap: 10px;
    color: var(--Gray-White, var(--Color-Gray-White, #FFF));
    text-align: center;
    font-size: 14px;
    font-weight: 400;
    height: 40px;

    .message_item{
      width: 100%;
      display: flex;
      flex-direction: row;
      font-size: 14px!important;
      align-items: center;
      img{
        border-radius: 50%;
        width: 24px;
        height: 24px;
        margin-right: 6px;
      }
      .name{
        padding-top: 3px;
        padding-bottom: 3px;
        white-space: nowrap;
        color: #BFC1CA;
        margin-right: 6px;
      }
      .text{
        flex-grow: 1;
        padding-top: 3px;
        padding-bottom: 3px;
        width: 100%;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
        word-break: break-all;
        -webkit-line-clamp: 1;
        margin-right: 10px;
        min-width: 70px;
      }
      .icon{
        width: 25px;
        svg{
          min-width: 25px;
        }
      }
    }
  }
}


@media all and (max-width: 1024px) {
  .Toastify__toast-container{
    width: 70%!important;
  }
  .image_modal{
    .mins_plus_box{
      display: none;
    }
  }
}
@media all and (max-width: 350px) {
  .C_Modal{
    &.C_innerBlackLineModal{
      max-width: 90vw;
    }
  }
}
@media all and (max-width: 320px) {
  .C_Modal{
    &.C_innerBlackLineModal{
      .message_item{
        max-width: unset;
      }
    }
  }
}
@media all and (max-width: 300px) {
  .C_Modal{
    &.C_innerBlackLineModal{
      .message_item{
        .text{
        }
        .icon{
          display: none;
        }
      }
    }
  }
}