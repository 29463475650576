@import 'src/variable';

.C_Main {
  background: $bg-grey-color;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  //height: 100vh;
  .layout_item {
    flex-grow: 1;

    &.C_LeftLayout {
      position: relative;
      padding: 40px;
      .banner_box {
        position: absolute;
        right: 40px;
        top: 50%;
        transform: translate(0, -50%);
      }
    }

    &.main_layout {
      display: flex;
      flex-direction: column;
      //height: 100vh;

      background-color: white;
      box-shadow: 0 0 50px 0 #e6e8ee;
      position: relative;
      margin: auto;
      width: 480px;
      max-width: 480px;
      .C_ChatError{
        padding: 40px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        flex: 1;
        gap: 16px;
        svg{
          width: 100px;
          height: 100px;
        }
        h1{
          font-size: 24px;
          font-weight: 400;
        }
        div{
          display: flex;
          flex-direction: column;
          align-items: center;
          color:#9698A3;
          font-size: 14px;
        }
      }
      .C_topAdArea {
        width: 100%;
        height: auto;
        img{
          width: 100%;
          object-fit: contain;
        }
      }
      .C_ChatHeader {
        height: 60px;
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        text-align: center;
        position: relative;
        border-bottom: 0.5px solid $text-grey-color;
        .user_name {
          flex-grow: 1;
          font-size: 19px;
          font-weight: 700;
        }
        .menu_btn {
          cursor: pointer;
          position: absolute;
          top: 0;
          right: 0;
        }
      }
      & > section {

      }
    }

    &.C_RightLayout {
      display: flex;
      flex-direction: row;
      align-items: start;
      justify-content: end;
      position: relative;

      .icon_box {
        padding: 40px;
        gap: 15px;
        svg {
          &:hover {
            box-shadow: 6px 9px 17px -6px #1f4bd5;
          }
        }
      }

      .profile_container {
        top: 50%;
        left: 10px;
        transform: translate(0, -50%);

        margin: auto auto auto 16px;
        height: fit-content;
        position: absolute;
        width: 328px;

        border-radius: 4px;
        border: 1px solid #dadbdf;
        background: #f6f6f8;
        box-shadow: 0 0 16px 0 #dadbdf;
        .cancel_box {
          height: 60px;
          display: flex;
          align-items: center;
          justify-content: end;
          .aaa {
            cursor: pointer;
            width: 60px;
            height: 60px;

            justify-content: center;
            align-items: center;
            display: flex;
          }
        }
        img {
          width: 96px;
          height: 96px;
          position: absolute;
          top: 60px;
          left: 50%;
          transform: translate(-50%, 0);
          z-index: 10;
          border-radius: 50%;
        }

        .inner_box {
          margin-left: 10px;
          margin-right: 10px;
          background-color: white;
          padding: 0 24px;
          &.top_box {
            border-radius: 8px;
            margin-top: 48px;
            position: relative;
            margin-bottom: 12px;
            .dummy_box {
              height: 50px;
            }
            .name_box {
              height: auto;
              .name {
                color: black;
                text-align: center;
                font-size: 24px;
                font-weight: 700;
                margin-top: 12px;
                margin-bottom: 8px;
              }
              .motto {
                margin: 8px auto 0 auto;
                background-color: #fafafa;
                border-radius: 12px;
                padding: 6px 12px;
                width: fit-content;
                word-break: break-all;
              }
            }
            .contour {
              margin: 24px 0;
              border-bottom: 1px solid #e6e8ee;
            }
            .info_box {
              font-size: 13px;
              display: flex;
              flex-direction: column;
              padding-bottom: 24px;
              gap: 10px;
              .info_item {
                display: flex;
                flex-direction: row;
                gap: 10px;
                .aaa {
                  color: #9698a3;
                  width: 70px;
                }
                .bbb{
                  font-size: 14px;
                }
              }
            }
          }
          &.history_box {
            margin-top: 12px;
            .item {
              .top {
                display: flex;
                flex-direction: row;
                gap: 8px;
                padding: 8px 16px;
                border-bottom: 1px solid #f2f3f6;
              }
              .bottom {
                padding: 12px 24px;
              }
            }
          }
        }
      }
    }
  }
}  // ********************** max ~ 1070 **********************
@media all and (max-width: 1200px) {
  .C_Main{
    .layout_item{
      &.C_RightLayout{
        justify-content: center;
        .icon_box{
          display: flex;
          padding: 40px 10px;
          gap: 10px;
          svg{
            height: 34px!important;
          }
        }
      }
    }
  }
}
@media all and (max-width: 1024px) {
  .C_Main{
    display: flex;
    .layout_item{
      &.C_RightLayout{
        position: fixed;
        z-index: 2;
        .icon_box{
          display: none;
        }
        .profile_container{
          z-index: 3;
          position: fixed;
          top:0;
          left: 0;
          right: 0;
          bottom: 0;
          transform: unset;
          width: 100vw;
          height: 100vh;
          margin: 0;
          border-radius: 0;
          border: unset;
          box-shadow: unset;
          .cancel_box{
            .aaa{
              width: 60px;
              height: 60px;
              display: flex;
              align-items: center;
              justify-content: center;
            }
          }
        }

        background-color: white;
        .profile_container{

        }
      }
      &.C_LeftLayout{
        display: none;
      }
      &.main_layout{
        width: 100%;
        max-width: unset;
      }
    }
  }
}
