@import 'src/variable';
.C_EncryptionKeyReasonModal{
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: white;
  width: 100%;
  z-index: 4;
  background-color: rgba(0,0,0,0.4);
  display: flex;
  justify-content: center;
  align-items: center;
  .body{
    background-color: white;
    width: 295px;
    border-radius: 4px;
    color: #000;

    .header{
      height: 56px;
      display: flex;
      padding: 20px 24px 12px 24px;
      align-items: center;

      text-align: center;
      font-size: 18px;
      font-weight: 700;
      justify-content: center;
    }
    .content{
      display: flex;
      flex-direction: column;
      padding: 0 24px 24px 24px;
      align-items: center;
      align-self: stretch;

      color: #3C3E44;
      font-size: 16px;
      font-weight: 400;
      line-height: 20px; /* 125% */
    }
    .footer{
      cursor: pointer;
      height: 44px;
      display: flex;
      align-items: center;
      justify-content: center;

      color: #1438CC;
      text-align: center;
      font-size: 16px;
      font-weight: 400;
      line-height: 20px; /* 125% */

      border-top: 0.5px solid var(--Gray-LightGray, #E6E8EE);
    }
  }
}
.C_EncryptionKey {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: white;
  width: 100%;
  z-index: 3;
  .header {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    height: 60px;
    position: relative;
    border-bottom: 0.5px solid $text-grey-color;
    background: white;
    .aaa {
      position: absolute;
      width: 56px;
      height: 60px;
      display: flex;
      align-items: center;
    }
    .text {
      width: 100%;
      text-align: center;
      font-weight: 700;
    }
  }
  .body {
    overflow: auto;
    padding: 60px 40px 60px 40px;
    display: flex;
    flex-direction: column;
    .top {
      padding: 0 40px;

      &.no_key {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        h1 {
          color: black;
          font-size: 24px;
          font-weight: 400;
          margin-bottom: 16px;
        }
        .no_key_guide {
          color: #9698a3;
          text-align: center;
          font-size: 14px;
          font-weight: 400;
        }
      }
      .key {
        margin-bottom: 32px;
        .qr {
          border-radius: 16px;
          background: $line-grey-color;
          padding: 20px;
          margin: 40px 40px 16px 40px;
        }
        .hash {
          text-align: center;
          width: 165px;
          word-wrap: break-word;
          margin: auto;
          font-weight: 700;
        }
      }
      .guide {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        gap: 25px;
        p {
          color: $dark-grey-color;
          text-align: center;
          font-size: 16px;
          font-weight: 400;
          text-align: center;
          &.blue {
            color: $main-blue-color;
            text-decoration-line: underline;
          }
        }
      }
    }
    .bottom {
      padding: 40px 0 10vh 0;
      button {
        width: 100%;
        height: 48px;

        border-radius: 8px;
        background: $dark-grey-color;

        color: white;
        font-size: 18px;
        font-weight: 700;
        margin-bottom: 16px;
      }
      .change_guide {
        text-align: center;
        color: $main-grey-color;
        font-size: 14px;
        font-weight: 400;
      }
    }
  }
}
@media all and (max-width: 1024px) {
  .C_EncryptionKey {
    .body {
      margin: auto;
      width: 480px;
      height: 100vh;
      .top{
        padding: 0 60px 0 60px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        .key{
          width: 222px;
          .qr{
            margin: 0 0 16px 0;
          }
        }
      }
    }
  }
}
@media all and (max-width: 480px) {
  .C_EncryptionKey {
    .body {
      width: 100%;
      overflow-y: scroll;
      .top{
        padding: 0;
        .guide{
        }
      }

    }
  }
}
@media all and (max-width: 320px) {
  .C_EncryptionKey {
    .body {
      padding: 60px 24px;
    }
  }
}