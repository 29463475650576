@import 'src/variable';
.C_UnReadMessagePercent{
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: white;
  z-index: 5;


  flex-grow: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin: auto;
  p{
    color: #9698A3;
    font-size: 16px;
    font-weight: 400;
    line-height: 20px; /* 125% */
    margin-top: 30px;
  }
}

.C_FullMessage{
  position: absolute;
  background-color: white;
  width: 100%;
  height: 100vh;
  .header{
    height: 60px;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    border-bottom: 0.5px solid #DADBDF;
    background: var(--Color-Gray-White, #FFF);

    text-align: center;
    font-size: 19px;
    font-weight: 700;
    .icon_box{
      cursor: pointer;
      position: absolute;
      width: 60px;
      height: 60px;
      top: 0;
      left:0;
      display: flex;
      align-items: center;
    }
  }
  display: flex;
  flex-grow: 1;
  flex-direction: column;
  p{
    padding: 16px;
  }
}

.C_ChatView {
  flex-grow: 1;
  display: flex;
  flex-direction: column;

  &.hidden{
    display: none!important;
  }
  .arrow_bottom_icon_box{
    position: absolute;
    bottom: 130px;
    right: 12px;
    cursor: pointer;
    background-color: #3C3E44;
    border-radius: 50%;
    width: 36px;
    height: 36px;
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 1;
    svg{
    }
  }
  .C_MessageOnClickModal{
    position:absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.4);
    z-index: 2;
    display: flex;
    align-items: center;
    justify-content: center;
    .content{
      background-color: #fafafa;
      width: 300px;
      height: auto;
      display: flex;
      flex-direction: column;
      border-radius: 12px;
      font-size: 16px;
      .item{
        padding: 0 24px;
        cursor: pointer;
        display: flex;
        flex-direction: row;
        height: 48px;
        align-items: center;
        justify-content: space-between;
        &.delete{
          color:#EA001D;
        }
      }
    }
  }
  .C_ChatList {
    flex-grow: 1;
    position: relative;
    padding-top: 80px;


    .chat_expire_time_box {
      position: absolute;
      top: 6px;
      width: calc(100% - 12px);
      left: 50%;
      transform: translate(-50%, 0);

      border-radius: 4px;
      background: rgba(60, 62, 68, 0.8);
      backdrop-filter: blur(1.5px);

      display: flex;

      padding: 10px;
      flex-direction: column;
      align-items: center;
      gap: 2px;
      z-index: 1;
      .info {
        text-align: center;
        color: #ffd6d6;
      }
      .time {
        color: white;
      }
    }
    .body {
      padding: 0 10px;
      flex-grow: 1;
      .date_box {
        position: sticky;
        margin: 16px 0;
        display: flex;
        flex-direction: row;
        flex-grow: 1;
        justify-content: center;
        align-items: center;
        .line {
          flex-grow: 1;
          border-bottom: 1px solid $line-grey-color;
        }
        .chat_send_date {
          display: flex;
          color: $main-grey-color;
          text-align: center;
          font-size: 12px;
          font-weight: 400;
        }
      }


      .C_ChatItem {
        display: flex;
        flex-direction: row;
        position: relative;
        margin-bottom: 16px;
        .chat_tail_icon {
          position: absolute;
          right: 0;
        }
        &.delete{
          .message {
            color:#BFC1CA!important;
          }
        }
        .tob_box {
          margin-bottom: 3px;
          .message {
            display: flex;
            flex-direction: row;
            max-width: 300px;
            white-space: pre-line;
            word-wrap: break-word;
            word-break: break-all;

            overflow:hidden;
            text-overflow:ellipsis;
          }
          .file_box {
            .file_other_box {
              cursor: pointer;
              border-radius: 16px;
              border: 1px solid $line-grey-color;
              background: white;

              display: flex;
              flex-direction: row;
              height: 60px;
              gap: 10px;
              padding: 14px;
              align-items: center;

              .icon {
                border-radius: 10px;
                background: #f6f6f8;
                height: 40px;
                width: 40px;
                display: flex;
                align-items: center;
                justify-content: center;
              }
              .name {
                max-width: 50vw;
                color: #3c3e44;
                font-size: 14px;
                font-weight: 700;

                overflow: hidden;
                white-space: nowrap;
                text-overflow: ellipsis;
                word-break: break-all;
                -webkit-line-clamp: 1;
              }
              .size {
                text-align: right;
                color: #bfc1ca;
                font-size: 12px;
                font-weight: 400;
              }
            }
            img {
              //width: 275px;
              max-height: 400px;
              object-fit: contain;
              border-radius: 16px;
            }
          }
        }
        &.server {
          display: flex;
          flex-direction: column;
          align-items: center;
          align-self: stretch;

          color: $main-grey-color;
          text-align: center;
          font-size: 14px;
          font-weight: 400;
          margin-bottom: 8px;
          .chat_tail_icon{
            display: none;
          }
          .bottom_box {
            display: none;
          }
          .tob_box{
            .message{
              align-items: center;
            }
          }
        }

        // 내가 보낸것들
        &.me {
          align-items: end;
          justify-content: end;
          flex-direction: column;
          .chat_left_tail_icon {
            display: none;
          }
          .tob_box {
            display: flex;
            flex-direction: row;
            .message {
              display: inline-flex;
              padding: 8px 14px;
              //justify-content: flex-end;
              //align-items: flex-end;
              gap: 10px;

              border-radius: 16px;
              background: #eaeeff;
            }
          }
          .bottom_box {
            color: #bfc1ca;
            font-size: 10px;
            font-weight: 400;
            display: flex;
            flex-direction: row;
            .message_status {
              &.blue {
                position: absolute;
                right: 0;
                bottom: 14px;
              }
            }
          }
        }

        &.you {
          align-items: stacrt;
          //flex-direction: column;
          .chat_right_tail_icon {
            display: none;
          }
          .chat_left_tail_icon {
            right: unset;
            left: 0;
          }
          .left_box {
            img {
              cursor: pointer;
              width: 32px;
              min-width: 32px;
              height: 32px;
              border-radius: 50%;
            }
          }
          .right_box {
            .name {
              margin-left: 4px;
              color: #3c3e44;
              font-size: 12px;
              font-weight: 400;
              margin-bottom: 4px;
            }
          }
          .tob_box {
            display: flex;
            flex-direction: row;

            position: relative;
            &.red{
              flex-direction: column;

              border-radius: 16px;
              background: #FFEDED;

              display: inline-flex;
              padding: 8px 14px;
              align-items: flex-start;
              gap: 8px;
              .aaa{
                color: var(--Sub-PoppyRed, #E55757);
                font-size: 16px;
                font-weight: 400;
                line-height: 20px; /* 125% */
                display: flex;
                flex-direction: row;
              }
              .bbb{
                color: #616371;
                font-size: 14px;
                font-style: normal;
                font-weight: 400;
                line-height: 18px; /* 128.571% */
              }
              .ccc{
                display: flex;
                padding: 6px 0px;
                justify-content: center;
                align-items: center;
                gap: 4px;
                align-self: stretch;

                border-radius: 15px;
                background: var(--Sub-LightRed, #ED8C8C);

                color: var(--Gray-White, var(--Color-Gray-White, #FFF));
                text-align: center;
                font-size: 14px;
                font-style: normal;
                font-weight: 700;
                line-height: 18px; /* 128.571% */
              }
            }

            .message {
              color: #000;
              font-size: 16px;
              font-weight: 400;
              border-radius: 16px;
              background: #f6f6f8;

              display: flex;
              padding: 8px 14px;
              align-items: flex-start;
              gap: 10px;
            }
            .file_box{
              margin-left: 5px;
            }
          }

          .bottom_box {
            color: #bfc1ca;
            font-size: 10px;
            font-weight: 400;
            position: relative;
            .message_status {
              &.blue {
                position: absolute;
                left: 0;
                bottom: 14px;
              }
            }
          }
        }
      }
    }
  }
  .C_ChatSend {
    border-top: 0.5px solid $grey-color;
    background: $bg-grey-color-two;
    display: flex;
    flex-direction: row;

    // v1. 모바일일때 키보드가 튀어나올경우 키보드에 가려짐
    position: relative;

    // v2. 전체 길이가 얼마든 모조건 가장 아래에
    //position: absolute;
    //bottom: 0;
    //right: 0;
    //left: 0;

    padding: 6px 12px 6px 0;
    .plus_box {
      height: 44px;
      min-width: 44px;
      display: flex;
      justify-content: center;
      align-items: center;
      padding: 14px 0;
      svg {
        cursor: pointer;
      }
    }
    textarea {
      padding: 10px 46px 10px 16px;
      border-radius: 20px;
      resize: none;
      width: 100%;
    }
    .send_icon {
      cursor: pointer;
      position: absolute;
      right: 17px;
      top: 11px;
    }
  }
}
.shadow {
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  background: rgba(0, 0, 0, 0.4);
  z-index: 1 !important;
}
.C_Setting {
  z-index: 2;
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0;
  width: 80%;
  height: 100vh !important;
  background: white;
  display: flex;
  flex-direction: column;
  .setting_header {
    height: 60px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-end;
    svg {
      cursor: pointer;
    }
  }
  .setting_body {
    padding: 0 16px 16px 16px;

    h6 {
      color: $dark-grey-color;
      font-size: 14px;
      font-weight: 700;
      height: 48px;
      display: flex;
      align-items: center;
    }
    .key {
      border-bottom: 0.5px solid $light-grey-color;
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: space-between;
      span{
        width: 48px;
        height: 48px;
        display: flex;
        justify-content: center;
        align-items: center;
      }
    }
    .member {
      display: flex;
      flex-direction: column;
      & > div {
        display: flex;
        flex-direction: row;
        align-items: center;
        color: $dark-grey-color;
        font-size: 16px;
        img {
          width: 32px;
          height: 32px;
          margin-right: 8px;
          border-radius: 50%;
        }
        &.me {
          font-weight: 700;
          margin-bottom: 12px;
          svg {
            margin-right: 4px;
          }
        }
        &.you {
          font-weight: 400;
        }
      }
    }
  }
  .setting_footer {
    height: 117px;
    border-top: 0.5px solid var(--gray-light-gray, #e6e8ee);
    background: var(--gray-bg-gray, #fafafa);

    display: flex;
    flex-direction: row;

    color: #9698a3;
    padding: 8px;
    .text {
      margin-top: 2px;
      color: #9698a3;
      font-size: 10px;
      font-weight: 400;
    }
    & > div {
      height: 44px;
      display: flex;
      flex-direction: column;
      align-items: center;
    }
  }
}
@media all and (max-width: 320px) {
  .C_ChatView {
    .C_MessageOnClickModal{
      .content{
        width: 100%;
        margin: 24px;
      }
    }
  }
}
