.C_Error {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  min-height: 500px;
  height: calc(100vh - 638px); // header + footer의 크기
  text-align: center;

  .error_content {
    svg {
      margin: auto;
      margin-bottom: 40px;
    }
    .error_img {
      width: 700px;
      height: auto;
    }
    .error_text {
      color: #848484;
    }
    /*    .move_home_btn{
      display: flex;
      align-items: center;
      justify-content: center;

      margin: 24px auto 0 auto;
      width: 175px;
      height: 50px;
      border-radius: 16px;
      border: 2px solid #E6E8EE;

      font-size: 18px;
      font-weight: 700;
    }*/
  }
}
.C_Loading {
  height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
